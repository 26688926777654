import { useCallback } from 'react'
import { useQuery } from 'react-query'
import { bookingService } from '@service/booking'
import { useLocaleConfig } from '@contexts/config'
import { useSearch } from '@contexts/search'
import { defaultOpenHoursStartTime, defaultOpenHoursEndTime } from '@util/config'
import { DOOR_TO_DOOR } from '@util/constants'
import { generateTimesForTimePicker } from '@util/functions'
import { differenceInCalendarDays, format, parse } from 'date-fns'
import { useGlobalState } from '@contexts/global'
import { DateTimeSelectorTypes } from '@util/enums'
import { DateCalculation } from '@service/booking.types'
import { ProductType } from '@service/configuration.types'

export const useDateCalculation = (
    listingId?: number | string,
    callback?: (date: Date, type: DateTimeSelectorTypes) => void,
): {
    dateCalculationResult: DateCalculation | undefined
    dateCalculationLoading: boolean
    dateCalculationError: boolean
    fillOutTimePicker: (dateType: DateTimeSelectorTypes) => void
} => {
    const { citySettings } = useLocaleConfig()
    const { bookingState } = useGlobalState()
    const { setFromDate, fromDate, toDate, setTimeOptions, setClosedDates, address } = useSearch()
    const isMonthly = bookingState.productType === ProductType.MONTHLY

    const {
        data: dateCalculationResult,
        isLoading,
        isError,
    } = useQuery(
        ['dateCalculation', address.handbackAddress?.id || address.handoverAddress?.id || listingId || ''],
        () =>
            bookingService.dateCalculationForListing(listingId || 0, {
                selfPickupHandoverLocationId:
                    !isMonthly && address.handoverAddress?.id ? address.handoverAddress?.id : null,
                selfPickupHandbackLocationId:
                    !isMonthly && address.handbackAddress?.id ? address.handbackAddress?.id : null,
            }),
        {
            onSuccess: (data) => {
                const dateCalcResp = data?.data
                if (dateCalcResp?.firstAvailableHandoverDate && fromDate) {
                    const firstValidFrom = new Date(dateCalcResp?.firstAvailableHandoverDate)
                    if (differenceInCalendarDays(fromDate, firstValidFrom) < 0) {
                        setFromDate(firstValidFrom)
                        callback &&
                            typeof callback === 'function' &&
                            callback(firstValidFrom, DateTimeSelectorTypes.from)
                    }
                }

                const closedDates = dateCalcResp?.unavailableDates.map((x) => new Date(x)) || []
                setClosedDates(closedDates)
            },
            enabled: !!listingId || (!!listingId && !!address.handbackAddress?.id && !!address.handoverAddress?.id),
        },
    )

    const fillOutTimePicker = useCallback(
        (dateType: DateTimeSelectorTypes) => {
            const date = dateType === DateTimeSelectorTypes.from ? fromDate : toDate
            if (date) {
                const dayName = format(date, 'EEEE').toUpperCase()

                const openingHour = address.handoverAddress?.openingHours?.find(
                    (oph) => oph.dayOfWeek.toLowerCase() === dayName.toLowerCase(),
                )

                const startHour =
                    openingHour?.openingTime || citySettings?.businessHours[dayName]?.start || defaultOpenHoursStartTime
                const endHours =
                    openingHour?.closingTime || citySettings?.businessHours[dayName]?.end || defaultOpenHoursEndTime

                const start = parse(startHour, 'HH:mm:ss', date)
                const end = parse(endHours, 'HH:mm:ss', date)

                const timeList = generateTimesForTimePicker(
                    citySettings,
                    bookingState.deliveryType === DOOR_TO_DOOR,
                    start,
                    end,
                )
                setTimeOptions(timeList)
            }
        },
        [
            address.handoverAddress?.openingHours,
            bookingState.deliveryType,
            citySettings,
            fromDate,
            setTimeOptions,
            toDate,
        ],
    )

    return {
        dateCalculationResult: dateCalculationResult?.data,
        dateCalculationLoading: isLoading,
        dateCalculationError: isError,
        fillOutTimePicker,
    }
}
