export const ClockIcon: React.FC<{ color?: string }> = ({ color = '#FF5A5A' }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" color={color} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM9.78 5H9.72C9.32 5 9 5.32 9 5.72V10.44C9 10.79 9.18 11.12 9.49 11.3L13.64 13.79C13.98 13.99 14.42 13.89 14.62 13.55C14.83 13.21 14.72 12.76 14.37 12.56L10.5 10.26V5.72C10.5 5.32 10.18 5 9.78 5Z"
                fill="currentColor"
            />
        </svg>
    )
}
