import { DatePickerProvider } from '@contexts/datepicker'
import { DateTimeSelectorTypes } from '@util/enums'
import { useEffect, useState } from 'react'
import { useMediaQuery } from '@hooks/mediaQuery'
import { device } from '@util/responsive'
import { Spinner } from '@components/global/Spinner'
import dynamic from 'next/dynamic'

const DesktopDatepicker = dynamic(
    () => import('./DesktopDatePicker').then(({ DesktopDatepicker }) => DesktopDatepicker),
    {
        ssr: false,
        loading: () => <Spinner />,
    },
)

const DesktopDatepickerModal = dynamic(
    () => import('./DesktopDatepickerModal').then(({ DesktopDatepickerModal }) => DesktopDatepickerModal),
    {
        ssr: false,
        loading: () => <Spinner />,
    },
)

const MobileDatePicker = dynamic(() => import('./MobileDatePicker').then(({ MobileDatePicker }) => MobileDatePicker), {
    ssr: false,
    loading: () => <Spinner />,
})

export const Datepicker: React.FC<{
    setTimeModal: React.Dispatch<React.SetStateAction<boolean>>
    maxBookingDays?: number
    minBookingDays?: number
    minBookingFromDate?: Date
    isReturnDateChange?: boolean
    isModalDateTimePickerDesktop?: boolean
    onSave?: (endDate: Date | null) => void
    calendarSelector?: DateTimeSelectorTypes | null
    scrollToDate?: Date | undefined
    isSingleDaySelectionMode?: boolean
    datePickerTitle?: string
}> = ({
    setTimeModal: setModal,
    maxBookingDays,
    minBookingDays,
    isReturnDateChange,
    onSave,
    isModalDateTimePickerDesktop,
    minBookingFromDate,
    calendarSelector,
    scrollToDate,
    isSingleDaySelectionMode,
    datePickerTitle,
}) => {
    const isDesktop = useMediaQuery(device.laptop)
    const [isReady, setIsReady] = useState<boolean>(false)

    useEffect(() => {
        setIsReady(true)
    }, [])

    function renderSwitchDatePicker() {
        if (isDesktop && !isModalDateTimePickerDesktop) {
            return (
                <DesktopDatepicker
                    isSingleDaySelectionMode={isSingleDaySelectionMode}
                    calendarSelector={calendarSelector}
                />
            )
        } else if (isDesktop && isModalDateTimePickerDesktop) {
            return <DesktopDatepickerModal setModal={setModal} isSingleDaySelectionMode={isSingleDaySelectionMode} />
        } else {
            return <MobileDatePicker scrollToDate={scrollToDate} isSingleDaySelectionMode={isSingleDaySelectionMode} />
        }
    }
    return isReady ? (
        <DatePickerProvider
            setModal={setModal}
            maxBookingDays={maxBookingDays}
            minBookingDays={minBookingDays}
            minBookingFromDate={minBookingFromDate}
            isReturnDateChange={isReturnDateChange}
            onSave={onSave}
            isDesktop={isDesktop}
            onlyOneDaySelectable={isSingleDaySelectionMode}
            isSingleDaySelectionMode={isSingleDaySelectionMode}
            datePickerTitle={datePickerTitle}
        >
            {renderSwitchDatePicker()}
        </DatePickerProvider>
    ) : null
}

export default Datepicker
