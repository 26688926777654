import { SpinnerWrapper } from '@components/modules/Cars/CarList'
import { BarSpinner } from './icons/SpinnerBar'

export const Spinner = ({
    text,
    subText,
    marginTopBottom = 50,
    width = 50,
    height = 50,
    spinnerColor = 'var(--primaryColor)',
}: {
    text?: string
    subText?: string
    marginTopBottom?: number
    width?: number
    height?: number
    spinnerColor?: string
}) => (
    <SpinnerWrapper marginTopBottom={marginTopBottom} direction="column" align="center">
        <BarSpinner color={spinnerColor} width={width} height={height} />
        {text && <p>{text}</p>}
        {subText && <span>{subText}</span>}
    </SpinnerWrapper>
)
